<template>
  <div>
    <teleport to="body">
      <modal
        ref="inactivityModal"
        :title="translate('core.inactivityWarningModalTitle')"
        :show="showInactivityModal">
        <template #default>
          {{ translate("core.inactivityWarning") }}
        </template>
        <template #footer>
          <form-button type="primary" @click="stayActiveRequested">
            {{ translate("core.stayConnectedButtonText") }}
          </form-button>
        </template>
      </modal>
      <modal
        ref="disconnectedModal"
        :show="showDisconnectedModal"
        :title="translate('core.disconnectWarningModalTitle')">
        <template #default>
          {{ translate("core.disconnectWarning") }}
        </template>
        <template #footer>
          <form-button type="primary" @click="disconnectApproveHandlerInternal">
            {{ translate("core.ok") }}
          </form-button>
        </template>
      </modal>
    </teleport>
  </div>
</template>

<script>
import InactivityDisconnectMixin from "@/client/extensions/mixins/baseComponents/etc/InactivityDisconnect.js";

let conf = { ...config.InactivityDisconnectMixin };

export default {
  mixins: [InactivityDisconnectMixin],
};
</script>

<style scoped lang="scss"></style>
